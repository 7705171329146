import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import btnArrow from "../assets/img/section-btn-arrow.webp";
import tickMark from "../assets/img/check-mark.webp";
import standardIcon from "../assets/img/std-icon.webp";
import premiumIcon from "../assets/img/pre-icon.webp";
import useCartStore from "../api/cart";
import { PopupWidget, PopupButton } from "react-calendly";
function PlanScreen() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleBox = () => {
    setIsOpen(!isOpen);
  };

  const { cartItems, updateCartItem } = useCartStore();
  const location = useLocation();
  const selectedType = location?.state?.selectedItem ? location?.state?.selectedItem?.name : localStorage.getItem("selectedItem");
  const pincode = location.state?.pincodeData || "";

  const [selectedPlan, setSelectedPlan] = useState(
    localStorage.getItem("SelectedPlan") || ""
  );

  const [selectedTask] = useState(() => {
    const savedTask = localStorage.getItem("selectedTask");
    return savedTask || "";
  });

  const [selectedOption] = useState(() => {
    const savedOption = localStorage.getItem("selectedOption");
    return savedOption || "";
  });

  const handlePlanSelection = (plan) => {
    setSelectedPlan(plan);
    localStorage.setItem("SelectedPlan", plan);
  };

  const navigate = useNavigate();

  const handleApply = () => {
    if (selectedPlan && localStorage.getItem("SelectedPlan")) {
      const updatedServices = cartItems[0]?.itemsData?.map(
        (service, index, arr) => {
          if (service?.name === location?.state?.selectedItem?.name || service?.name == localStorage.getItem('selectedItem')) {
            return {
              ...service,
              pricing_plan: arr.length - 1 == index ? localStorage.getItem('SelectedPlan') : service.pricing_plan,
            };
          } else {
            return service;
          }
        }
      );
      updateCartItem(cartItems[0].id, {
        itemsData: updatedServices,
      });
      navigate("/alteration", {
        state: {
          selectedPlan,
          selectedType,
          selectedItem: location?.state?.selectedItem,
        },
      });
    }
  };

  useEffect(() => {
    if (cartItems && cartItems[0]?.itemsData?.length > 0) {
      const filteredItem = cartItems[0]?.itemsData?.filter(
        (itm) => itm?.name === location?.state?.selectedItem?.name
      );
      setSelectedPlan(filteredItem[0]?.pricing_plan);
    }
    if (!localStorage.getItem("addnewitem")) {
      if (
        cartItems[0]?.itemsData?.length >= 1 &&
        cartItems[0]?.services?.length >= 1
      ) {
        navigate("/item-detail");
      }
    }
  }, []);

  return (
    <section className="select-plan-section same-bg-section relative">
      <div className="container  padding-x-all">
        <div className="select-plan-box sec-com-padding flex-box justify-between align-center">
          <div
            className={`show-box ${
              isOpen ? "open mobile-none" : "mobile-none"
            }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {selectedType}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {selectedPlan
                  ? selectedPlan
                  : localStorage.getItem("SelectedPlan")}
              </h4>
            </div>
          </div>

          <div
            className={`show-box ${
              isOpen ? "desktop-none" : "open desktop-none"
            }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {selectedType}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {selectedPlan ? selectedPlan : localStorage.getItem("SelectedPlan")}
              </h4>
            </div>
          </div>

          <div className="select-plan-inner-box">
            <h2>Select a FXRY plan that suits your item.</h2>
            <button
              onClick={() => handlePlanSelection("Standard")}
              className={localStorage.getItem("SelectedPlan") === "Standard"
                  ? "plan-selected standard-btn flex-box justify-between align-center"
                  : "standard-btn flex-box justify-between align-center"
              }
            >
              <div className="icon-box">
                <img src={standardIcon} alt="" />
              </div>
              <div className="text-box">
                <h3>FXRY Standard Pricing</h3>
                <h4>Top-notch quality for your everyday essentials.</h4>
                <p className="para">
                  With our team of skilled fixers, you can count on impeccable
                  craftsmanship every time.
                </p>
              </div>
              <div className="check-box">
                <img src={tickMark} alt="" />
              </div>
            </button>

            <button
              onClick={() => handlePlanSelection("Premium")}
              className={localStorage.getItem("SelectedPlan") === "Premium"
                  ? "plan-selected premium-btn flex-box justify-between align-center"
                  : "premium-btn flex-box justify-between align-center"
              }
            >
              <div className="icon-box">
                <img src={premiumIcon} alt="" />
              </div>
              <div className="text-box">
                <h3>FXRY Premium Pricing</h3>
                <h4>
                  Ideal for precious, intricate, or sentimental pieces, as well
                  as high-value items.
                </h4>
                <p className="para">
                  Our most seasoned fixers will meticulously handle your
                  garments, ensuring the utmost quality.{" "}
                  <span>
                    REQUIRED FOR LEATHER, SILK, SUEDE AND EMBELLISHED ITEMS.
                  </span>{" "}
                </p>
              </div>
              <div className="check-box">
                <img src={tickMark} alt="" />
              </div>
            </button>
            <p className="unsure-btn">
              <PopupButton
                url="https://calendly.com/fxry/video-call-with-a-fxry-fixer"
                rootElement={document.getElementById("root")}
                text="Unsure? Book a free virtual fixer consultation"
              />
            </p>
            <button
              className={`${localStorage.getItem("SelectedPlan") ? "section-main-btn" : "section-main-btn1"}`}
              type="button"
              disabled={localStorage.getItem("SelectedPlan") ? false : true}
              onClick={handleApply}
            >
              Select a service{" "}
              <span>
                <img src={btnArrow} alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlanScreen;